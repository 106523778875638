<template>
  <div>
    <van-popup round v-model:show="pageStatus" teleport="body">
      <div class="fen-xiang">
        <div style="height: 360px;">
          <van-image class="lxr-code" width="100%" :src="codeInfo.image"/>
        </div>
        <div class="ti-shi">
          <div style="text-align: center">长按上方图片保存到手机</div>
          <div
              class="fu-zhi-lian-jie"
              :data-clipboard-text="codeInfo.url"
          >
            <div class="lian-jie van-ellipsis">{{ codeInfo.url }}</div>
            点击复制链接
          </div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import http from "../../api/http";
import Clipboard from 'clipboard'
import din_shi from "../../api/din_shi";

export default {
  name: 'getFenXiangCode',
  mixins: [],
  data() {
    return {
      codeInfo: {},
      pageStatus: false
    }
  },
  mounted() {

  },
  methods: {
    show() {
      http.get('/gzhphp/code/getCode').then(da => {
        if (da.code === 1) {
          this.codeInfo = da.data
          this.$nextTick(this.setCopy)
        }
      })
    },
    setCopy() {
      this.pageStatus = true
      let clipboard = new Clipboard('.fu-zhi-lian-jie')
      clipboard.on('success', e => {
        din_shi.success('复制成功')
        //  释放内存
        try {
          clipboard.destory()
        } catch (e) {
        }
      })
      clipboard.on('error', e => {
        // 不支持复制
        din_shi.error('该浏览器不支持复制')
        // 释放内存
        try {
          clipboard.destory()
        } catch (e) {
        }
      })
    },
    watch: {},
    computed: {}
  }
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.fen-xiang {
  width: 317px;
  min-height: 200px;
  background-color: @white;
  //border-radius: 10px;
  .ti-shi {
    background-color: @blue;
    color: @white;
    padding: 15px 10px;
    font-size: 16px;
    margin-top: -5px;

    .fu-zhi-lian-jie {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;

      .lian-jie {
        width: 183px;
      }
    }

  }
}

.lxr-code {
  ::v-deep img {
    line-height: 0;
    font-size: 0;
  }
}
</style>
