<template>
  <div class="dai-dan-div">
    <div class="yi-ji">
      <van-row>
        <van-col span="12">
          我承接的项目
        </van-col>
        <van-col span="12" style="text-align: right">
          <a href="javascript:" @click="href('all')">
            全部 <i class="iconfont icon-xiangxiazhanhang"></i>
          </a>
        </van-col>
      </van-row>
    </div>
    <div class="din-dan-type">
      <van-grid :column-num="4">
        <van-grid-item @click="href(1)" icon="iconfont iconfont icon-querenhetongquerenhetongqueding"
                       text="已报价"/>
        <van-grid-item @click="href(2)" icon="iconfont iconfont icon-jinhangzhong" text="进行中"/>
        <van-grid-item @click="href(3)" icon="iconfont iconfont icon-complete" text="已完成"/>
        <van-grid-item @click="$router.push({name:'WoDeXuQiu'})" icon="iconfont iconfont icon-baojiafangan" text="我的商机"/>
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dingDanNav',
  mixins: [],
  mounted() {
  },
  methods: {
    href(typeName) {
      this.$router.push({name: 'WoChengJieDeXiangMu', query: {typeName: typeName}})
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.dai-dan-div {
  color: @gray-8;
  //box-shadow: 0 2px 4px #ecdddd6e;
  background: @white;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: @yin-ying-all;

  .yi-ji {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid @gray-3;
    font-size: 14px;
  }
}

.din-dan-type {
  ::v-deep .van-grid-item__text {
    margin-top: 10px;
    color: @blue;
  }

  ::v-deep .van-icon {
    color: @blue;
  }

  [class*='van-hairline']::after {
    border: none;
  }

  ::v-deep .van-grid-item__content::after {
    border-width: 0;
  }
}
</style>
