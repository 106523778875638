<template>
  <div>
    <div class="dai-dan-div">
      <div class="din-dan-type">
        <van-grid :column-num="4">
          <van-grid-item
              v-if="userInfo?.dang_qian_jiao_se === '服务商'"
              @click="$refs.keFuId.onSelect({type: '留言反馈'})"
              icon="1 iconfont icon-kefuxiaoxi"
              text="留言建议"
          />
          <van-grid-item
              v-if="userInfo?.dang_qian_jiao_se !== '服务商'"
              :dot="daiPingJiaLength > 0"
              :to="{name:'DaiPingJia'}"
              icon="1 iconfont icon-ping_lun"
              text="项目评价"
          />
          <van-grid-item @click="$refs.keFuId.onSelect({type: '在线客服'})" icon="1 iconfont icon-kefu" text="在线客服"/>
          <van-grid-item @click="$refs.keFuId.onSelect({type: '客服电话'})" icon="1 iconfont icon-dianhua" text="客服电话"/>
          <van-grid-item :to="{name:'MessageList'}" icon="1 iconfont icon-xiao_xi" :dot="weiDu > 0" text="消息中心"/>
        </van-grid>
      </div>
      <lian-xi-ke-fu ref="keFuId"/>
      <zui-xing-xiang-mu v-model:list="zuiXingList" v-if="jiaoSe == '客户'"/>
    </div>
  </div>

</template>

<script>
import {userMessage, getDaiPingLun, userBasis} from "./Basis";
import LianXiKeFu from "../../open/LianXiKeFu";
import {ref, onMounted} from 'vue'
import ZuiXingXiangMu from "@/views/user/public/ZuiXingXiangMu";
import _ from 'lodash'

export default {
  name: 'userKeFuNav',
  components: {ZuiXingXiangMu, LianXiKeFu},
  data() {
    return {
      zuiXingList: []
    }
  },
  setup(props, context) {
    let {userInfo, jiaoSe} = userBasis()
    let {weiDu} = userMessage()
    let {daiPingJiaLength} = getDaiPingLun()
    const keFuId = ref(null)
    return {
      jiaoSe,
      userInfo,
      weiDu,
      daiPingJiaLength
    }
  },
  watch: {
    zuiXingList: {
      handler() {
        if (!_.isEmpty(this.zuiXingList)) {
          this.$emit('update:height', 80)
        } else {
          this.$emit('update:height', 20)
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.din-dan-type /deep/ .van-grid-item__content--center {
  padding-left: 10px;
  padding-right: 10px;
}

.dai-dan-div {
  color: @gray-8;
  box-shadow: 0 2px 4px #ecdddd6e;
  background: @white;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;

  .yi-ji {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid @gray-3;
    font-size: 14px;
  }
}

.din-dan-type {
  ::v-deep .van-grid-item__text {
    margin-top: 10px;
    color: @blue;
  }

  ::v-deep .van-icon {
    color: @blue;
  }

  [class*='van-hairline']::after {
    border: none;
  }

  ::v-deep .van-grid-item__content::after {
    border-width: 0;
  }
}
</style>
