<template>
  <div class="user-top">
    <div class="user-nav">
      <nav-header
          class="nav-fff"
          title="个人中心"
          :dong-tai-color="{top:150,color:'74,64,150'}"
      >
        <template #right>
            <span @click="userSetkeHu">
              <i class="iconfont icon-jiantou_zuoyouqiehuan"></i>&nbsp;切换到客户
            </span>
        </template>
      </nav-header>
    </div>
    <div class="user-info" @click="$router.push({name:'UserInfo'})">
      <div class="tou-xiang">
        <van-image
            round
            width="59"
            height="59"
            :src="`${userInfo.avatar}`"
        />
      </div>
      <div class="user-text">
        <div class="user-name van-ellipsis">
          {{ userInfo.user_nickname }}
        </div>
        <div class="user-g-anme van-ellipsis">
          {{ gName }}
          <template v-if="zhiWei">
            - {{ zhiWei }}
          </template>
        </div>
      </div>
    </div>
    <div class="chan-ping" style="margin-top: 30px">
      <user-ke-fu-nav/>
    </div>
  </div>
  <div class="chan-ping" style="margin-top: 20px">
    <ding-dan-nav/>
  </div>
  <div class="user-dao-hang">
    <van-cell-group>
      <van-cell is-link :to="{name:'WoDeXuQiu'}">我的商机</van-cell>
    </van-cell-group>
  </div>
  <div class="user-dao-hang" v-if="isFzr">
    <van-cell-group>
      <van-cell is-link :to="{name:'FuWuShangInfo',query:{g_id:userInfo.dang_qian_g_id}}">我的店铺</van-cell>
      <van-cell is-link :to="{name:'CaseList'}">案例管理</van-cell>
    </van-cell-group>
  </div>
  <div class="user-dao-hang">
    <van-cell-group>
      <van-cell is-link @click="showCode">我要分享</van-cell>
      <van-cell is-link :to="{name:'Recommender'}">我的邀请</van-cell>
    </van-cell-group>
  </div>
  <div class="user-dao-hang">
    <van-cell-group>
      <van-cell is-link :to="{name:'UserInfo'}">账号信息</van-cell>
      <van-cell is-link :to="{name:'EditPassword'}">修改密码</van-cell>
      <van-cell is-link :to="{name:'EditMobile'}">修改手机号</van-cell>
      <van-cell is-link @click="showGongSi">切换服务商</van-cell>
      <van-cell is-link @click="tuiChu">退出</van-cell>
    </van-cell-group>
  </div>
  <qie-huan-gong-si ref="qieHuanGongSiDiv"/>
  <get-fen-xiang-code ref="codeDiv"/>
  <div style="height: 80px;"></div>
  <footer-nav fixed path-name="UserHome"/>
</template>

<script>
import UserKeFuNav from "./public/userKeFuNav";

export default {
  name: 'UserFws',
  components: {UserKeFuNav},
}
</script>
<script setup>
import NavHeader from "../../components/top/NavHeader";
import FooterNav from "../open/footerNav";
import FooterLianXikeFu from "../open/footerLianXikeFu";
import QieHuanGongSi from "./public/qieHuanGongSi";
import DingDanNav from "./public/dingDanNav";
import GetFenXiangCode from "./getFenXiangCode";
import {userBasis} from "./public/Basis";
import {ref} from "vue";


let {isFzr, zhiWei, userInfo, userSetkeHu, gName, tuiChu} = userBasis()
let qieHuanGongSiDiv = ref(null)

function showGongSi() {
  qieHuanGongSiDiv.value.show()
}

let codeDiv = ref(null)

function showCode() {
  codeDiv.value.show()
}
</script>


<style scoped lang="less">
@import "../../assets/user-home";

.user-top {
  width: 100%;
  background-image: url("../../assets/icon/user_bei_jing.png");
  background-size: 100% auto;
  background-position-y: 0;
  background-repeat: no-repeat;
  //max-height: 250px;

  .user-nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}
</style>
