<template>
  <div class="user-top">
    <div class="user-nav">
      <nav-header
          :dong-tai-color="{top:150,color:'74,64,150'}"
          class="nav-fff"
          title="个人中心"
      >
        <template #right>
            <span @click="userSetFws">
              <i class="iconfont icon-jiantou_zuoyouqiehuan"></i>&nbsp;切换到服务商
            </span>
        </template>
      </nav-header>
    </div>
    <div class="user-info" @click="$router.push({name:'UserInfo'})">
      <div class="tou-xiang">
        <van-image
            round
            width="59"
            height="59"
            :src="userInfo.avatar"
        />
      </div>
      <div class="user-text">
        <div class="user-name van-ellipsis">
          {{ userInfo.user_nickname }}
        </div>
        <div class="user-g-anme van-ellipsis">
          {{ gName }}
          <template v-if="zhiWei">
            - {{ zhiWei }}
          </template>
        </div>
      </div>
    </div>
    <div class="chan-ping" style="margin-top: 30px">
      <user-ke-fu-nav v-model:height="chanPingHeight"/>
    </div>
  </div>
  <!--  <div :style="{height:chanPingHeight+'px'}"></div>-->

  <div class="user-dao-hang" v-if="userInfo?.open_home === '开启'">
    <van-cell-group>
      <van-cell is-link :to="{name:'CustomerPages',params:{g_id:userInfo.dang_qian_g_id}}">客户主页</van-cell>
    </van-cell-group>
  </div>

  <div class="user-dao-hang">
    <van-cell-group>
      <van-cell is-link :to="{name:'WoDeXuQiu'}">我的需求</van-cell>
      <van-cell is-link :to="{name:'WoDeXiangMu'}">我的项目</van-cell>
    </van-cell-group>
  </div>
  <div class="user-dao-hang">
    <van-cell-group>
      <van-cell is-link @click="showCode">我要分享</van-cell>
      <van-cell is-link :to="{name:'Recommender'}">我的邀请</van-cell>
    </van-cell-group>
  </div>
  <div class="user-dao-hang">
    <van-cell-group>
      <van-cell is-link :to="{name:'UserInfo'}">账号信息</van-cell>
      <van-cell is-link :to="{name:'EditPassword'}">修改密码</van-cell>
      <van-cell is-link :to="{name:'EditMobile'}">修改手机号</van-cell>
      <van-cell is-link @click="showGongSi">
        切换公司
      </van-cell>
      <van-cell is-link @click="tuiChu">退出</van-cell>
    </van-cell-group>
  </div>
  <qie-huan-gong-si ref="jiaoSe"/>
  <get-fen-xiang-code ref="codeDiv"/>
  <div style="height: 100px;"></div>
  <footer-nav fixed path-name="UserHome"/>

</template>

<script>


import UserKeFuNav from "./public/userKeFuNav";

export default {
  name: 'UserKeHu',
  components: {UserKeFuNav},
}
</script>
<script setup>
import {ref, computed} from "vue";
import NavHeader from "../../components/top/NavHeader";
import {getUserInfo, setFws} from "../../api/dataList/userApi";
import {cutString, hidden} from "../../api/GongJu";
import FooterNav from "../open/footerNav";
import FooterLianXikeFu from "../open/footerLianXikeFu";
import GetFenXiangCode from "./getFenXiangCode";
import {userBasis, userMessage} from "./public/Basis";
import QieHuanGongSi from "./public/qieHuanGongSi";

let {isFzr, zhiWei, userInfo, gName, userSetFws, tuiChu} = userBasis()
let jiaoSe = ref(null)
let chanPingHeight = ref(10)

function showGongSi() {
  try {
    jiaoSe.value.show()
  } catch (e) {
    console.log(e)
  }
}

let codeDiv = ref(null)

function showCode() {
  codeDiv.value.show()
}
</script>
<style scoped lang="less">
@import "../../assets/user-home";

.user-top {
  width: 100%;
  //height: 165px;
  background-image: url("../../assets/icon/user_bei_jing.png");
  background-size: 100% auto;
  background-position-y: 0;
  background-repeat: no-repeat;
  //max-height: 230px;

  .user-nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}
</style>
