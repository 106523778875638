<template>
  <div style="height: 100%">

    <user-fws v-if="userInfo?.dang_qian_jiao_se === '服务商'"/>
    <user-ke-hu v-else/>

  </div>
</template>

<script>
export default {
  name: 'UserHome',
}
</script>
<script setup>
import {userBasis} from "./public/Basis";
import UserKeHu from "./UserKeHu";
import UserFws from "./UserFws";

let {userInfo} = userBasis()
</script>

<style scoped>

</style>
