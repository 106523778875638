<template>
  <div @click.stop="tiaoZhuan">
    <div class="user-dao-hang" v-if="info.xiang_mu.length">
      <div class="he-tong-name">最新项目</div>
      <div class="name van-ellipsis">{{ info.name }}：{{ getType }}</div>
    </div>
  </div>
</template>

<script>
import http from "@/api/http";
import XEUtils from "xe-utils";

export default {
  name: "ZuiXingXiangMu",
  data() {
    return {
      info: {
        xiang_mu: []
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    tiaoZhuan() {
      this.$router.push({
        name: 'WoDeXiangMu',
        query: {
          keyword: this.info.name
        }
      })
    },
    getData() {
      http.get("/gzhphp/user_xiang_mu/zui_xing_xiang_mu").then(da => {
        if (!XEUtils.isEmpty(XEUtils.get(da,'data.info.xiang_mu'))) {
          this.info = da.data.info
          this.$emit('update:list', this.info.xiang_mu)
        }
      })
    }
  },
  computed: {
    getType() {
      return XEUtils.map(this.info.xiang_mu, n => n.name).join('、')
    }
  }
}
</script>

<style scoped lang="less">
@import "~@/assets/basis_css/style/var";

.user-dao-hang {
  background-color: @gray-1;
  margin: 0 10px 10px;
  padding: 10px 5px;
  font-size: 13px;
  border-radius: 5px;
  z-index: 10;

  .name {
    color: @gray-6;
  }
}
</style>